<script setup>
import {useTheme} from 'vuetify'
import Dates from '@/paks/js-dates'
import {onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue'
import {Auth, Chat, State, getRoutePath} from '@/paks/vu-app'
import {
    Storage,
    can,
    delay,
    getModel,
    getModels,
    getVuetify,
    navigate,
    waitRender,
} from '@/paks/vu-app'

const page = reactive({
    alerts: [],
    cart: false,
    brightModes: {auto: '$auto', dark: '$dark', light: '$light'},
    features: State.config.features.nav,
    finished: null,
    hasWizard: false,
    hasMock: false,
    loading: false,
    loadingChat: false,
    logo: null,
    mock: State.app.mock,
    name: null,
    ready: false,
    sleep: 0,
    showBar: null,
    showHelp: false,
    social: State.auth.social,
    title: null,
})

//  Component references
const progress = ref(null)
const feedback = ref(null)
const theme = useTheme()
const {Dashboard} = getModels()

var helpPanel

onMounted(async () => {
    page.logo = State.app.logo

    if (page.features.help) {
        helpPanel = State.ref.vue._context.components['HelpPanel']
    }
    setBar()
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        if (State.app.bright == 'auto') {
            State.app.dark = e.matches
            getVuetify().theme.global.name.value = State.app.dark ? 'dark' : 'light'
            State.app.setNeed('dash', 'refresh')
        }
    })
    let query = State.app.location.query
    if (query.chat) {
        help()
        await Chat.open()
        State.app.noNeed('help')
    }

    watch(
        () => `${State.auth.accountName}-${State.auth.ready}`,
        async () => {
            if (State.auth.ready) {
                setMode()
                if (State.config.name == 'builder') {
                    page.hasMock = (can('support') || (State.auth.email.indexOf('@embedthis.com') > 0)) ? true : false
                    if (page.hasMock) {
                        page.mock = State.app.mock = Storage.getValue(`/navbar/mock`) || false
                    }
                }
                page.title = State.app.title || State.app.display.title
                if (State.config.profile == 'qa') {
                    page.title = 'EmbedThis Builder'
                }
                page.ready = State.auth.ready
                await getAccountName()
                daemon()
                checkWizards()
                checkCart()
                page.ready = true
            }
        },
        {immediate: true}
    )
    watch(
        () => State.app.needs.help,
        async (value) => {
            let query = State.app.location.query
            if (value == 'chat') {
                help()
                await Chat.open()
                State.app.noNeed('help')
            } else if (value) {
                help()
                State.app.noNeed('help')
            } else if (query.help || query.chat) {
                help()
            }
        }
    )
    watch(
        () => {
            return State.app.needs.repaint
        },
        async (value) => {
            if (State.auth.ready) {
                setMode()
                setBar()
                getAccountName()
                await getAlerts()
            }
        }
    )
    watch(
        () => State.app.needs.alertUpdate,
        async () => {
            await getAlerts()
        }
    )
    watch(
        () => State.cache.dashboard,
        () => {
            setBar()
        },
        {deep: true, immediate: true}
    )

    watch(
        () => getRoutePath(),
        () => setBar
    )

    if (State.cache.plans) {
        watch(State.cache.plans, () => {
            checkCart()
        })
    }

    State.ref.router.afterEach((from, to) => {
        setTimeout(() => checkWizards(), 500)
    })
})

onBeforeUnmount(() => {
    page.finished = true
})

function setBar() {
    page.showBar = State.cache.dashboard?.full ? false : true
}

async function daemon() {
    watch(
        () => State.app.lastAccess,
        async () => {
            //  On user click, if been asleep for more than 5 minutes, update alerts
            if (page.sleep > 5 * 60 * 1000) {
                await getAlerts()
            }
        }
    )
    while (!page.finished) {
        await getAlerts()
        let idle = Date.now() - State.app.lastAccess
        //  Increasing sleep time based on user activity. Minimum of 60 secs, max 6 hours.
        page.sleep = Math.max(60 * 1000, Math.min(6 * 3600 * 1000, idle / 2))
        await delay(page.sleep)
    }
}

async function getAlerts() {
    if (!page.ready || !State.auth.authorized || page.features.alerts == false) {
        return
    }
    try {
        let Alert = getModels().Event || getModels().Alert
        if (Alert) {
            page.alerts = await Alert.find({}, {limit: 5})
            for (let alert of page.alerts) {
                alert.date = Dates.format(alert.date, State.config.formats.time)
                if (alert.severity == 'info') {
                    // alert.color = 'green'
                    alert.icon = '$info'
                } else if (alert.severity == 'warning') {
                    // alert.color = 'error'
                    alert.icon = '$warn'
                } else {
                    // alert.color = 'green'
                    alert.icon = '$info'
                }
            }
        }
    } catch (err) {}
}

async function getAccountName() {
    if (State.app.mock) {
        page.name = 'user@example.com'
    } else {
        page.name = State.auth.email || State.auth.username
    }
}

async function checkWizards() {
    await waitRender()
    let wizards = document.getElementsByClassName('vu-wizard')
    page.hasWizard = wizards.length
}

function toggleSidebar() {
    State.sidebar.toggle()
}

function changeDisplay() {
    let keys = Object.keys(page.brightModes)
    let index = keys.indexOf(State.app.bright)
    if (++index >= keys.length) {
        index = 0
    }
    State.app.bright = keys[index]
    setMode()
    State.app.setNeed('dash', 'refresh')
}

function setMode() {
    let mode = State.app.bright
    if (!mode || mode == 'auto') {
        mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }
    State.app.dark = mode == 'dark'
    theme.global.name.value = mode
}

function docClick() {
    window.open(page.features.doc, '_blank')
}

function cartClick() {
    navigate('/account/subscription')
}

function login() {
    navigate('/auth/login')
}

async function logout() {
    await Auth.logout({redirect: true})
    navigate('/auth/login')
}

async function help() {
    if (page.showHelp) {
        page.showHelp = false
        Chat.close()
    } else {
        openHelp()
    }
}

function openHelp() {
    page.showHelp = true
}

function closeHelp() {
    page.showHelp = false
    Chat.close()
}

function wizard() {
    State.app.launchWizard()
}

async function designMode() {
    State.app.setNeed('dash', 'design')
}

async function tellus() {
    page.loadingChat = true
    await Chat.toggle()
    page.loadingChat = false
}

function mockClick() {
    State.app.mock = !State.app.mock
    page.mock = State.app.mock
    State.app.setNeed('dash', 'refresh')
    Storage.setValue(`/navbar/mock`, page.mock)
}

function alertClick(alert) {
    let target
    if (State.config.builder) {
        target = '/automations/alerts'
    } else if (getModel('Event')) {
        target = '/events'
    } else {
        target = '/alerts'
    }
    if (alert) {
        navigate(target, {alert: alert.id})
    } else {
        navigate(target)
    }
}

async function logoClick() {
    if (State.app?.display?.theme?.logoLink) {
        window.open(State.app?.display?.theme?.logoLink, '_blank')
    }
}

function checkCart() {
    page.cart = State.app.getCart().length
}
</script>

<template>
    <div class="nav-toolbar" v-show="page.ready" :class="`${page.showBar ? 'show' : 'hide'}`">
        <v-app-bar v-if="page.showBar" color="primary" density="compact" class="pa-0">
            <template v-slot:prepend>
                <v-app-bar-nav-icon class="toggle" @click.stop="toggleSidebar" />
            </template>

            <img :src="page.logo" height="40" @click="logoClick" />

            <v-toolbar-title class="headline align-center">
                <a @click="navigate('/')">{{ page.title }}</a>
            </v-toolbar-title>

            <v-spacer />

            <v-toolbar-items class="right-toolbar">
                <div class="icons">
                    <v-btn icon>
                        <v-icon icon="$tools" id="tools" @click="designMode" />
                    </v-btn>
                    <v-btn icon v-if="page.hasWizard">
                        <v-icon icon="$wizard" id="launch-wizard" @click="wizard" />
                    </v-btn>
                    <v-btn icon v-if="page.features.doc">
                        <v-icon icon="$bookshelf" @click="docClick" />
                    </v-btn>
                    <v-btn icon v-if="page.hasMock">
                        <v-icon :icon="page.mock ? '$stop' : '$play'" @click="mockClick"></v-icon>
                    </v-btn>
                    <v-btn icon v-if="page.cart">
                        <v-icon icon="$cart" @click="cartClick" />
                    </v-btn>
                    <v-btn v-if="page.features.dark" icon @click="changeDisplay">
                        <v-icon :icon="page.brightModes[State.app.bright]" color="accent" />
                    </v-btn>
                </div>

                <v-menu location="bottom" v-if="page.alerts?.length">
                    <template v-slot:activator="{props}">
                        <v-btn text icon v-bind="props">
                            <v-icon icon="$alert" color="white" />
                        </v-btn>
                    </template>
                    <v-list density="compact" min-width="400" class="nav-alerts">
                        <v-list-subheader class="header">
                            You have {{ page.alerts?.length || 0 }} alert notice{{
                                page.alerts?.length == 1 ? '' : 's'
                            }}
                        </v-list-subheader>
                        <v-list-item
                            v-for="(alert, i) in page.alerts"
                            :key="alert.id"
                            @click="alertClick(alert)">
                            <v-row>
                                <v-col cols="1">
                                    <v-icon
                                        :icon="alert.icon"
                                        :class="`severity-${alert.severity}`"
                                        size="x-large" />
                                </v-col>
                                <v-col cols="6">
                                    <span class="subject">
                                        {{ alert.subject }}
                                    </span>
                                </v-col>
                                <v-col cols="5">
                                    <v-icon icon="$clock" class="clock" size="x-large" />
                                    <span class="alert-date">
                                        {{ alert.date }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <v-spacer></v-spacer>
                        <v-list-subheader class="footer" @click="alertClick()">
                            View all alerts
                        </v-list-subheader>
                    </v-list>
                </v-menu>

                <v-btn text v-if="!page.ready && !State.app.mobile">
                    <a @click="login">Login</a>
                </v-btn>

                <v-menu class="pulldown" v-if="page.ready && !State.app.mobile">
                    <template v-slot:activator="{props}">
                        <v-btn text v-bind="props">{{ page.name }}</v-btn>
                    </template>
                    <v-list class="nav-menu">
                        <v-list-item to="/account/profile" v-if="page.features.profile">
                            <v-icon icon="$account" />
                            Profile
                        </v-list-item>
                        <v-list-item
                            to="/account/settings"
                            v-if="can('admin') && page.features.settings">
                            <v-icon icon="$cog" />
                            Account Settings
                        </v-list-item>
                        <v-list-item @click="logout">
                            <v-icon icon="$run" />
                            Logout
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn v-if="helpPanel" text class="help" @click="help">Help</v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <div v-else class="nav-icon"
            :class="`${State.cache.dashboard?.fixed ? 'fixed' : ''}
            ${State.cache.dashboard?.full ? 'full' : ''}`">
            <v-icon icon="$menu" @click.stop="toggleSidebar" />
        </div>

        <vu-feedback ref="feedback" />
        <vu-progress
            class="progress"
            :class="State.cache.dashboard?.full ? 'full' : ''"
            ref="progress" />

        <vu-panel
            v-if="helpPanel"
            v-model="page.showHelp"
            :isHelp="true"
            :classes="['help-panel']"
            :widths="['40%']"
            @close="closeHelp">
            <component v-bind:is="helpPanel" @input="closeHelp" />
        </vu-panel>

        <v-progress-circular
            v-if="page.loadingChat"
            class="loadingChat"
            color="primary"
            indeterminate
            :size="50" />
    </div>
</template>

<style lang="scss">
.nav-toolbar {
    height: 48px;
    background: rgb(var(--v-theme-background-lighten-1));
    &.hide {
        height: 0;
    }
    .v-toolbar__prepend {
        margin-inline: 0;
    }
    .v-toolbar__content {
        padding-right: 0;
    }
    .v-app-bar {
        box-shadow: none;
        z-index: 201 !important;
        .headline {
            min-width: 130px;
            flex-grow: 10;
            @media (max-width: 640px) {
                text-align: center;
            }
        }
        a {
            text-decoration: none;
        }
        button {
            text-decoration: none;
        }
        .v-app-bar__nav-icon {
            margin-left: 0 !important;
        }
        .icons {
            height: inherit;
        }
        .right-toolbar {
            //  Above feedback
            z-index: 202;
        }
    }
    .nav-icon {
        position: fixed;
        top: 0;
        left: 0;
        height: 68px;
        width: 68px;
        z-index: 300;
        padding: 12px;
        background: none;
        opacity: 1;
        @media (max-width: 640px) {
            padding: 12px !important;
        }
        @media (prefers-color-scheme: dark) {
            opacity: 0.75;
        }
    }
    .nav-icon.full {
        margin-top: 10px;
        margin-left: 6px;
    }
    .nav-icon:not(.fixed) {
        background: none;
    }
    .progress {
        position: relative;
        top: 48px;
    }
    .progress.full {
        top: 0;
    }
}
.pulldown .v-overlay__content {
    border-radius: 0 !important;
}
.nav-menu {
    padding: 10px 0 0 0 !important;
    .v-icon {
        margin-right: 6px;
        width: 30px;
    }
    .items {
        font-size: 1rem;
    }
    hr {
        margin: 14px 0 14px 0;
    }
    .v-list-item {
        min-height: 40px !important;
        margin-right: 20px;
        width: 100%;
        .current {
            font-weight: bold;
        }
    }
    .v-list-item__content {
        padding: 6px 12px 6px 0 !important;
    }
    .v-list-item__icon {
        margin: 10px !important;
    }
}
.theme {
    margin-top: 12px !important;
    .v-input--switch__track {
        color: rgb(var(--v-theme-primary));
    }
}
.nav-alerts {
    background-color: rgb(var(--v-theme-background-lighten-1)) !important;
    border: solid 1px rgba(var(--v-theme-border-darken-1)) !important;
    padding-bottom: 0 !important;
    .header {
        font-weight: bold;
        font-size: 16px !important;
        margin: 0 0 10px 8px;
    }
    .footer {
        background-color: rgb(var(--v-theme-background-lighten-2));
        padding: 10px;
        padding-left: 20px;
        font-size: 14px !important;
        cursor: pointer;
    }
    .v-list-item {
        font-size: 12px !important;
        .v-list-item__content {
            margin: 0;
            display: inline-block;
            .v-icon {
                font-size: 1.8rem;
                margin-right: 8px;

                &.severity-undefined {
                    color: green;
                }
                &.severity-info {
                    color: green;
                }
                &.severity-warn {
                    color: orange;
                }
                &.severity-error {
                    color: red;
                }
                &.severity-critical {
                    color: red;
                }
            }
            .subject {
                display: inline-block;
                margin-top: 4px !important;
            }
            .v-icon.clock {
                display: inline-block;
                font-size: 16px;
            }
            .alert-date {
                margin-top: 4px !important;
                display: inline-block;
            }
        }
    }
}
.help-panel {
    padding: 48px 0 0 0;
    z-index: 200 !important;
    min-width: 600px;
    .panel.default {
        padding: 0 !important;
    }
}
.tellus {
    position: fixed !important;
    bottom: 0;
    color: white !important;
    background: rgb(var(--v-theme-accent)) !important;
    margin: 0;
    left: 50%;
    z-index: 1;
}
.loadingChat {
    position: fixed;
    left: 48%;
    top: 48%;
    z-index: 202;
}
</style>
