<script setup>
import {onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import {State, can, clone, getModels, getValue, waitRender} from '@/paks/vu-app'

const props = defineProps({
    dashboard: Object,
    widget: Object,
})

const page = reactive({
    active: true,
    canEdit: null,
    currentRange: null,
    dash: null,
    dashboardNames: [],
    expand: false,
    hasMetrics: null,
    loadingRange: null,
    multiple: null,
    range: {anchor: 'relative', period: 3600},
    refresh: 60,
    saving: null,
    showWidgetEdit: false,
    showDashEdit: false,
})

const self = ref(null)
const {Dashboard} = getModels()

defineExpose({update})

onMounted(() => {
    let features = State.config.features.dash || {}
    let dashboard = props.dashboard
    page.hasMetrics =
        features.metrics && dashboard.widgets.filter((w) => w.metric).length ? true : false
    page.canEdit = can('admin') && features.edit
    page.dashboardNames = State.cache.dashboards.map((d) => d.name).sort()
    page.refresh = getValue(dashboard.refresh || State.config?.timeouts?.dashboard || 60)
    page.multiple = features.multiple
    Object.assign(page.range, dashboard.range)
    page.currentRange = clone(page.range)
    props.widget.height = 56

    watch(() => page.range, () => rangeUpdated())
})

onUnmounted(() => {
    page.active = false
})

function update() {
    let el = self.value
    if (el.clientWidth && props.widget.type == 'toolbar') {
        //  Plus padding
        props.widget.width = el.clientWidth + 16
    }
    page.dashboardNames = State.cache.dashboards.map((d) => d.name).sort()
}

function percent() {
    return Math.max(0, 100 - (props.dashboard.remaining / page.refresh) * 100)
}

async function rangeUpdated() {
    page.loadingRange = true
    await waitRender()
    if (State.auth.ready) {
        if (!page.currentRange || page.range.period != page.currentRange.period) {
            page.currentRange = clone(page.range)
            props.dashboard.range = page.range
            State.app.setNeed('save')
        }
        State.app.setNeed('dash', 'refresh')
    }
    page.loadingRange = false
}

function refresh() {
    State.app.setNeed('dash', 'refresh')
}

function resetDashboard() {
    State.app.setNeed('dash', 'reload')
}

function compactWidgets() {
    State.app.setNeed('save')
    State.app.setNeed('dash', 'compact')
}

function editDashboard() {
    State.app.setNeed('dash', 'editDash')
}

function editWidget() {
    State.app.setNeed('dash', 'editWidget')
}

function expandWidgets() {
    State.app.setNeed('save')
    State.app.setNeed('dash', 'expand')
}

async function changeDashboard(name) {
    let dashboard = State.cache.dashboards.find((d) => d.name == name)
    await Dashboard.set(dashboard)
    State.app.setNeed('dash', 'reload')
}

async function changeDesign() {
    State.app.setNeed('dash', 'design')
    // dashboard.design = !dashboard.design
    State.app.setNeed('save', false)
}

async function save() {
    page.saving = true
    let dashboard = props.dashboard
    dashboard.range = page.range
    await Dashboard.set(dashboard)
    await Dashboard.update(dashboard)
    page.saving = false
    State.app.setNeed('save', false)
    State.app.setNeed('dash', 'reload')
}
</script>

<template>
    <div ref="self" class="toolbar-widget">
        <v-toolbar _color="background" density="compact" class="toolbar" :class="dashboard.full && !dashboard.design ? 'full' : ''">
            <div class="toolbar-icons">
                <vu-pick
                    v-if="
                        page.canEdit &&
                        page.multiple &&
                        page.dashboardNames.length > 1 &&
                        !(State.app.needs.save && page.mobile)
                    "
                    align="right"
                    class="pick-dashboard mr-4"
                    :label="dashboard.name"
                    :items="page.dashboardNames"
                    @click="changeDashboard" />

                <vu-date
                    v-if="true || page.hasMetrics"
                    v-model="page.range"
                    class="date-picker"
                    mode="date"
                    :loading="page.loadingRange"
                    size="small" />

                <v-btn
                    v-if="dashboard?.widgets?.length && dashboard.live"
                    variant="text"
                    class="icon reload"
                    v-tip.bottom="'Reload Data'"
                    @mousedown.stop="refresh">
                    <v-progress-circular
                        :size="26"
                        :width="3"
                        :model-value="percent()"
                        color="primary-lighten-1">
                        {{ dashboard.remaining }}
                    </v-progress-circular>
                </v-btn>

                <v-icon
                    v-if="page.canEdit"
                    icon="$tools"
                    class="icon"
                    size="large"
                    v-tip.bottom="dashboard.design ? 'Design Mode' : 'Production Mode'"
                    :color="dashboard.design ? 'primary' : 'accent'"
                    @mousedown.stop="changeDesign()" />

                <v-icon
                    v-if="page.canEdit && dashboard.layout == 'grid' && !dashboard.snap"
                    icon="$shrink"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Compact Widgets'"
                    color="primary-lighten-1"
                    @mousedown.stop="compactWidgets" />
                <v-icon
                    v-if="page.canEdit && dashboard.layout == 'grid'"
                    icon="$stretch"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Expand Widgets'"
                    color="primary-lighten-1"
                    @mousedown.stop="expandWidgets" />

                <v-icon
                    v-if="page.canEdit && !page.mobile"
                    icon="$reset"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Reset Dashboard'"
                    color="primary-lighten-1"
                    @mousedown.stop="resetDashboard" />

                <v-icon
                    v-if="page.canEdit"
                    icon="$dashboard"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Modify Dashboard'"
                    :color="true ? 'primary-lighten-1' : ''"
                    @mousedown.stop="editDashboard(dashboard)" />

                <v-icon
                    v-if="page.canEdit"
                    icon="$plusBoxOutline"
                    class="icon"
                    size="large"
                    v-tip.bottom="'Add Widget'"
                    :color="true ? 'primary-lighten-1' : 'secondary'"
                    @mousedown.stop="editWidget({})" />

                <v-btn
                    v-if="State.app.needs.save"
                    size="small"
                    class="dash-save"
                    :loading="page.saving"
                    @mousedown.stop.stop="save">
                    Save
                </v-btn>

                <v-btn
                    v-if="page.item && !State.app.needs.save"
                    class="extras"
                    variant="outlined"
                    @mousedown.stop="navigate('/devices')">
                    {{ page.item }}
                </v-btn>
            </div>
        </v-toolbar>
    </div>
</template>

<style lang="scss">
.toolbar-widget {
    display: inline-block;
    padding: 8px 8px 8px 0;
    min-width: 460px;
    z-index: 10;
    .v-toolbar {
        width: fit-content !important;
        max-width: unset !important;
        border: none !important;
        background: rgb(var(--v-theme-background-lighten-1)) !important;
        .v-btn {
            font-size: 0.8rem;
        }
        .v-toolbar__content {
            overflow: visible;
        }
        .dashname {
            flex-grow: 0;
        }
        .pick-dashboard {
            cursor: pointer;
            .v-btn {
                font-size: 1rem;
            }
        }
        .vu-pick {
            margin-right: 8px !important;
            .v-btn {
                font-size: 0.75rem;
            }
        }
        .toolbar-icons {
            display: inline-flex;
            vertical-align: middle;
            align-items: flex-end;
            height: 36px;
            padding-bottom: 4px;
            .icon {
                margin-right: 8px;
                height: 26px;
                font-size: 22px;
                padding-bottom: 8px;
                cursor: pointer;
                @media (max-width: 640px) {
                    margin-right: 8px;
                }
            }
            .reload {
                background: none !important;
                box-shadow: none !important;
                border: none !important;
                height: 20px !important;
                width: 20px !important;
                min-width: 0 !important;
                margin: 0 4px 10px 0 !important;
            }
            .reload:hover {
                color: rgba(0, 0, 0, 0);
            }
            .extras {
                font-size: 1rem;
                border: 1px solid rgb(var(--v-theme-border));
                border: none;
                color: rgb(var(--v-theme-primary));
            }
        }
        .date-range {
            .v-btn {
                min-width: unset !important;
                font-size: 0.75rem;
            }
        }
        .custom-input {
            width: 95px !important;
        }
        .dash-btn {
            margin: 0 10px 0 8px !important;
            order: 1px solid rgb(var(--v-theme-border));
            box-shadow: none !important;
        }
        .dash-save {
            padding: 0;
            margin: 0 10px 4px 4px !important;
            background: rgb(var(--v-theme-accent));
            color: white;
        }
    }
    .v-toolbar.full {
        padding-left: 52px;
    }
}
</style>
