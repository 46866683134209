<script setup>
/*
    GraphWidget.vue -- This is an opinioned wrapping of Chart.js graphs to display line and timeline graphs.
    Use chart.js directly if you require a different look and feel or data management.

    data: {
        // These lables are for the x-axis ticks
        labels: [ '11 AM', '12 PM', '1 PM', '2 PM', ... ],
        datasets: [{
            data: [ 1134, 1351, 2685, 2954, 4395, ... ],
                or
            data: [ {x, y}, {x, y}, ... ],
            label: 'Data Set Title',
        }]
    }

    --w-background-color
    --w-background-border
    --w-point-border-color
    --w-point-background-color
    --w-border-color
*/

import {
    Chart,
    CategoryScale,
    Filler,
    LineController,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    TimeScale,
    Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import {onBeforeUnmount, onMounted, reactive, ref} from 'vue'

const props = defineProps({
    state: Object,
    widget: Object,
})

const page = reactive({
    rendering: false,
})

const Units = [
    // {value: 0.001, title: 'millisecond'},
    // {value: 1, title: 'second'},
    {value: 60, title: 'minute'},
    {value: 3600, title: 'hour'},
    {value: 86400, title: 'day'},
    // {value: 86400 * 7, title: 'week'},
    {value: 86400 * 28, title: 'month'},
    // {value: 86400 * 92, title: 'quarter'},
    {value: 86400 * 365, title: 'year'},
]

const MainLightTheme = {
    backgroundColor: 'rgba(30, 70, 160, 0.5)',
    backgroundBorder: 'rgba(30, 70, 160, 1)',
    pointBorderColor: 'rgba(50, 70, 160, 0.3)',
    pointBackgroundColor: 'rgba(50, 70, 160, 0.3)',
    borderColor: 'rgba(50, 70, 160, 0.3)',
}

const MainDarkTheme = {
    backgroundColor: 'rgba(30, 70, 160, 0.85)',
    backgroundBorder: 'rgba(30, 70, 160, 1)',
    pointBorderColor: 'rgba(50, 70, 160, 0.3)',
    pointBackgroundColor: '#82B1FF75',
    borderColor: '#82B1FF',
}

const CommonTheme = {
    borderWidth: 1,
    fill: {target: 'origin'},
    pointRadius: 3,
    skip: true,
    spanGaps: true,
    tension: 0.1,
}

//  If more than one dataset
const SecondaryTheme = {
    borderColor: 'rgba(255, 70, 160, 0.5)',
}

defineExpose({update})

const frame = ref(null)
const self = ref(null)

let canvas = null
let chart = null
let values = []

onMounted(async () => {
    Chart.register(
        CategoryScale,
        Filler,
        LineController,
        LinearScale,
        PointElement,
        LineElement,
        TimeScale,
        Title,
        Tooltip
    )
    await update()
})

onBeforeUnmount(() => {
    if (chart) {
        chart.destroy()
    }
})

function makeChart(data) {
    let widget = props.widget
    let canvas = frame.value
    if (!canvas) return

    data = makeData(data)

    let axes = widget.axes || {}
    /*
        Types: bar, line, scatter, bubble, pie, doughnut, polarArea, radar
        Time is an internal variant of line
    */
    let type = widget.presentation || 'time'
    let params = {
        type: type,
        data: data,
        options: {
            maintainAspectRatio: false,

            animation: {
                duration: 0,
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'top',
                },
                subtitle: {display: false},
                tooltips: {
                    mode: 'label',
                    callbacks: {
                        label: function (item, data) {
                            let amt = data.datasets[item.datasetIndex].data[item.index]
                            if (typeof amt == 'object' && typeof amt.y == 'number') {
                                amt = amt.y
                            }
                            if (typeof amt == 'number') {
                                amt = amt.toFixed(2)
                                return amt.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            return ''
                        },
                    },
                },
            },
            scales: {
                x: { },
                y: {
                    stacked: false,
                    beginAtZero: true,
                    ticks: {
                        min: widget.min,
                        max: widget.max,
                        callback: (value, index, values) => {
                            if (Math.abs(value) < 1) {
                                return parseFloat(value.toPrecision(3))
                            } else {
                                return value.toLocaleString()
                            }
                        },
                    },
                    title: {
                        display: axes.y ? true : false,
                        text: axes.y,
                    }
                },
            },
        },
    }
    if (type == 'time') {
        let maxTicks = widget.value?.datasets?.length
            ? Math.max(widget.value.datasets[0].data.length, 12)
            : 12
        Object.assign(params.options.scales.x, {
            type: 'time',
            distribution: 'linear',
            bounds: 'data',
            time: {
                displayFormats: {
                    millisecond: 'h:mm:ss',
                    second: 'h:mm:ss',
                    minute: 'h:mm',
                    hour: 'h:mm',
                    day: 'dd MMM',
                    month: 'MMM yyy',
                    quarter: 'MMM yyy',
                    year: 'YYYY',
                },
                unit: getUnits(widget.range?.period),
                minUnit: 'second',
            },
            ticks: {
                source: 'auto',
                maxTicksLimit: maxTicks,
            },
        })
        params.type = 'line'
    }
    Object.assign(params.options.scales.x, {
        scaleLabel: {
            display: axes.x ? true : false,
            labelString: axes.x,
        },
    })
    if (params.data && params.data.datasets && params.data.datasets.length) {
        let theme = Object.assign({}, CommonTheme, props.state.app.dark ? MainDarkTheme : MainLightTheme)

        //  Apply CSS overrides
        let widgetElement = self.value
        for (let term of Object.keys(theme)) {
            let prop = term.replace(/[A-Z]/g, '-$&').toLowerCase()
            theme[term] = widgetElement.style[`--w-${prop}`] || theme[term]
        }

        Object.assign(params.data.datasets[0], theme)
        if (params.data.datasets.length > 1) {
            Object.assign(params.data.datasets[1], SecondaryTheme)
        }
        if (params.data.datasets[0].label) {
            params.options.plugins.legend.display = true
        }
    }
    if (widget.options?.chart) {
        Object.assign(params, widget.options.chart)
    }
    if (chart) {
        chart.destroy()
    }
    chart = new Chart(canvas, params)
    chart.resize()
}

function getUnits(period = 3600) {
    let prior
    let interval = period / 2
    for (let unit of Units) {
        if (interval <= unit.value) {
            return prior?.title
        }
        prior = unit
    }
    return 'hour'
}

/*
    Data is [{x,y}, ...]
 */
function makeData(data) {
    if (data == null) {
        return []
    }
    if (typeof data == 'object') {
        return {datasets: [{data}]}
    }
    let now = Date.now()
    values.push({y: data, x: now})
    //  Trim items outside the period
    for (let point of values) {
        if (point.x < now - props.widget.range?.period * 1000) {
            values.shift()
        }
    }
    return {
        datasets: [{data: values}],
    }
}

async function update(params = {}) {
    if (params.initialize) {
        //  Future
    }
    let data = props.widget.value
    if (frame.value && !page.rendering && data != null) {
        page.rendering = true
        makeChart(data)
        page.rendering = false
    }
}
</script>

<template>
    <div class="graph-widget" ref="self">
        <canvas
            class="canvas"
            :class="`${props.widget.header ? 'with-header' : ''}`"
            id="graph"
            ref="frame" />
    </div>
</template>

<style lang="scss">
.graph-widget {
    container-type: size;
    height: 100%;
    .canvas {
        padding: 3cqh 3cqw 5cqh 3cqw;
    }
    .canvas.with-header {
        padding: 11cqh 3cqw 5cqh 3cqw;
    }
}
</style>
