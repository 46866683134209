<script setup>
import {onMounted, reactive, ref} from 'vue'
import {Feedback, Progress, State, can, fetch} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import {arrayBufferToBase64} from '@/paks/js-base64'
import {Account, Plan, Post, Product, Ticket} from '@/models'

const Severities = ['low', 'medium', 'high', 'critical']
const Statuses = ['open', 'closed', 'progressing', 'pending-customer-action']
const Staff = ['support@embedthis.com']
const MaxAttachment = 5 * 1024 * 1024
const MaxAttachments = 10

const props = defineProps({arg: Object})
const emit = defineEmits(['input'])

const page = reactive({
    account: {},
    basic: {current: {units: 0}, units: 0},
    developer: {current: {units: 0}, units: 0},
    hours: 0,
    hoursDialog: false,
    hoursLogged: false,
    ready: false,
    post: {},
    posts: [],
    products: [],
    rules: [],
    saving: false,
    staff: can('support'),
    ticket: props.arg ? props.arg : {status: 'open'},
    workDescription: null,
    uploadFiles: [],
})

//  Component references
const confirm = ref(null)
const form = ref(null)

defineExpose({page})

onMounted(async () => {
    Progress.start('medium')

    page.ticket.status = page.ticket.status || 'open'
    let products = await Product.find({accountId: page.staff ? page.ticket.accountId : undefined})
    page.products = [{name: 'General Query', id: '1'}].concat(products)

    if (props.arg && props.arg.id) {
        await getPosts(page.ticket)
        page.account = await Account.get({id: page.ticket.accountId})
        page.account = page.account || State.auth.account
    } else {
        Object.assign(page.ticket, initTicket())
        page.ticket.productId = page.products.length == 1 ? page.products[0].id : null
        page.account = State.auth.account
    }
    page.post = initPost(page.ticket)

    let plans = State.cache.plans
    if (page.ticket.accountId != State.auth.accountId) {
        plans = await Plan.find({accountId: page.ticket.accountId}, {refresh: 'bypass'})
    }
    page.basic = plans.find((p) => p.type == 'Support') || {current: {units: 0}, units: 0}
    page.developer = plans.find((p) => p.type == 'Developer') || {current: {units: 0}, units: 0}
    page.ready = true
    Progress.stop()
})

function initPost(ticket = {}) {
    let post = {}
    post.attachments = []
    post.message = null
    post.ticketId = ticket.id
    return post
}

function initTicket() {
    return {
        productId: (page.products[0] || {}).id,
        subject: '',
        severity: 'low',
        status: 'open',
        assigned: page.staff ? Staff[0] : undefined,
    }
}

async function getPosts(ticket) {
    page.posts = await Post.find({
        ticketId: ticket.id,
        accountId: page.staff ? ticket.accountId : undefined,
    })
    page.posts = page.posts.sort(sortPosts).reverse()
    for (let post of page.posts) {
        if (post.created) {
            post.date = Dates.format(post.created, 'h:MM TT mmm d, yyyy')
        }
    }
}

async function save() {
    let {post, ticket} = page
    if (page.staff && !page.hoursLogged) {
        page.hoursDialog = true
        return
    }
    Progress.start('dialog', 'Saving Post')
    post.attachments = await getAttachments()
    ticket.updated = new Date()

    if (!page.staff && post.message) {
        ticket.status = 'progressing'
    }
    if (ticket.id) {
        await Ticket.update({
            accountId: page.staff ? ticket.accountId : undefined,
            id: ticket.id,
            cc: ticket.cc,
            productId: ticket.productId,
            subject: ticket.subject,
            severity: ticket.severity,
            status: ticket.status,
            assigned: page.staff ? ticket.assigned : undefined,
            updated: ticket.updated,
        })
    } else {
        ticket = page.ticket = await Ticket.create(ticket)
    }
    if (post.message) {
        post.author = page.staff ? 'support@embedthis.com' : State.auth.user.email
        post.ticketId = ticket.id
        if (page.staff) {
            post.accountId = ticket.accountId
        }
        page.post = await Post.create(post)
    }
    Progress.stop()
    emit('input')
}

function reopenTicket() {
    page.ticket.status = 'open'
}

function closeTicket() {
    page.ticket.status = 'closed'
    save()
}

async function logHours() {
    if (page.ticket.id) {
        let hours = Math.round(((+page.hours || 0) + 0.075) * 4) / 4
        if (hours) {
            if (!page.workDescription) {
                Feedback.error('Must have a description')
                return
            }
            await Ticket.updateWorked({
                accountId: page.ticket.accountId || State.auth.accountId,
                id: page.ticket.id,
                hours,
                description: page.workDescription,
            })
        }
    }
    page.hoursLogged = true
    page.hoursDialog = false
    await save()
}

async function getAttachments() {
    let attachments = []

    if (page.uploadFiles.length > MaxAttachments) {
        throw new Error('Too many attachments')
    }
    for (let file of page.uploadFiles) {
        if (file.size > MaxAttachment) {
            throw new Error(
                `Attachment ${file.name} is bigger than max ${MaxAttachment / 1024 / 1024} MB`
            )
        }
        let reader = new FileReader()
        reader.readAsArrayBuffer(file)
        let data = await new Promise((resolve, reject) => {
            reader.onload = async () => {
                try {
                    resolve(reader.result)
                } catch (err) {
                    reject(err)
                }
            }
        })
        data = arrayBufferToBase64(data)
        attachments.push({name: file.name, size: file.size, data})
    }
    return attachments
}

async function download(post, attachment) {
    try {
        Progress.start('dialog', `Downloading ${attachment.name}`)
        let url = await Post.download({
            id: post.id,
            accountId: page.staff ? page.ticket.accountId : undefined,
            key: attachment.key,
        })
        let data = await fetch(url, {
            method: 'GET',
            credentials: false,
            nologout: true,
            // progress: true,
            blob: true,
        })
        let blob = new Blob([data])
        let a = document.createElement('a')
        a.download = attachment.name
        a.href = window.URL.createObjectURL(blob)
        document.body.append(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(blob)
    } catch (err) {
        Feedback.error('Cannot download attachment', {err})
    } finally {
        Progress.stop()
    }
}

async function deleteTicket() {
    if (
        !(await confirm.value.ask(
            `<p>Do you want to delete the case?</p>` +
                `<p>All the messages in this support case will be deleted.</p>`
        ))
    ) {
        return
    }
    Progress.start('dialog', 'Removing Ticket and Posts')
    await Ticket.remove(page.ticket, {refresh: true})
    Progress.stop()
    Feedback.info('Ticket Deleted')
    emit('input')
}

async function deletePost(post) {
    if (!(await confirm.value.ask(`Do you want to delete this post?`))) {
        return
    }
    Progress.start('dialog', 'Removing Post')
    await Post.remove({
        id: post.id,
        accountId: post.accountId,
        ticketId: post.ticketId,
    })
    await getPosts(page.ticket)
    Progress.stop()
}

function sortPosts(a, b) {
    if (a.created < b.created) {
        return -1
    } else if (a.created > b.create) {
        return 1
    } else {
        return 0
    }
}
</script>

<template>
    <vu-form
        class="ticket-edit"
        help="/doc/ui/support/case.html"
        ref="form"
        :data="page"
        :options="{enter: false, progress: 'dialog', title: 'Saving Post'}"
        :save="save">
        <vu-sign
            name="ticket-support-status"
            title="Suport Plan"
            color="accent"
            class="vcol-12"
            :required="true">
                <v-row v-if="page.staff">
                    <v-col>
                        <h3>Customer: {{ page.account.name }}, {{ page.account.email }}</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <label>Available Developer Support</label>
                        <p class="metric">
                            {{ page.developer.current.units }}
                            {{ page.developer.current.units == 1 ? 'hour' : 'hours' }}
                        </p>
                    </v-col>
                    <v-col cols="4">
                        <label>Pending Developer Support</label>
                        <p class="metric">
                            {{ page.developer.units }}
                            {{ page.developer.units == 1 ? 'hour' : 'hours' }}
                        </p>
                    </v-col>
                    <v-col cols="4">
                        <label>This Ticket</label>
                        <p class="metric">
                            {{ page.ticket.hours || 0 }}
                            {{ page.ticket.hours == 1 ? 'hour' : 'hours' }}
                        </p>
                    </v-col>
                </v-row>
        </vu-sign>

        <div v-if="page.staff && page.ready" class="vcol-12">
            <v-alert v-if="page.developer.current.units <= 0" type="error">
                Developer Hours Depleted
            </v-alert>
            <v-alert v-else-if="page.developer.current.units <= 1" type="warning">
                Developer Hours Low
            </v-alert>
            <v-alert v-if="!page.basic.units" type="error">Basic Support Not Enabled</v-alert>
        </div>

        <vu-sign
            name="ticket-add"
            :title="`${page.ticket.id ? 'Suport Case Reply' : 'Add Support Case'}`"
            :subtitle="`Case Number: ${page.ticket.id?.slice(-6)}, Status: ${page.ticket.status}`"
            color="accent"
            class="vcol-12"
            :required="true">
                <v-container fluid class="pb-5">
                    <vu-validate ref="validate" class="mb-5" />
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="page.ticket.subject"
                                label="Subject"
                                :rules="page.rules.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                v-model="page.post.message"
                                label="Description"
                                rows="15"
                                placeholder="Please provide as much detail as possible. Fully describe your issue, and environment."></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                :items="page.products"
                                density="compact"
                                v-model="page.ticket.productId"
                                label="Associated Product"
                                item-title="name"
                                item-value="id"
                                variant="underlined"
                                ></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-file-input
                                v-model="page.uploadFiles"
                                density="compact"
                                label="Attach files..." 
                                multiple
                                show-size
                                variant="underlined"
                                :clearable="true"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-select
                                density="compact"
                                v-model="page.ticket.severity"
                                label="Issue Severity"
                                variant="underlined"
                                :items="Severities" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                density="compact"
                                v-model="page.ticket.cc"
                                label="Additional Recipients"
                                placeholder="Emails separated by commas" 
                                variant="underlined"
                                />
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" v-if="page.staff">
                        <v-col cols="6">
                            <v-select
                                density="compact"
                                v-model="page.ticket.status"
                                label="Status"
                                :items="Statuses" 
                                variant="underlined" />
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                density="compact"
                                v-model="page.ticket.assigned"
                                label="Assigned"
                                :items="Staff" 
                                variant="underlined"
                                />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                size="small"
                                class="mr-2"
                                color="accent"
                                type="submit"
                                :loading="page.saving">
                                Save
                            </v-btn>
                            <v-btn
                                size="small"
                                dark
                                class="mr-2"
                                color="accent"
                                v-if="page.ticket.status != 'closed' && page.ticket.id"
                                @click="closeTicket">
                                Close Case
                            </v-btn>
                            <v-btn size="small" color="none" class="mr-2" @click="emit('input')">
                                Cancel
                            </v-btn>

                            <v-btn
                                size="small"
                                dark
                                color="teal"
                                class="mr-2"
                                v-if="
                                    page.ticket.id && page.staff && page.ticket.status == 'closed'
                                "
                                @click="reopenTicket">
                                Reopen Case
                            </v-btn>
                            <v-btn
                                size="small"
                                color="error"
                                class="mr-2"
                                v-if="page.ticket.id && page.staff"
                                @click="deleteTicket">
                                Delete
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
        </vu-sign>

        <div v-for="post in page.posts" :key="post.id" class="vrow mt-4">
            <v-card class="post vcol-12">
                <v-btn v-if="page.staff" variant="plain" class="close" @click="deletePost(post)">
                    <v-icon icon="$close" size="small" />
                </v-btn>
                <v-card-title>{{ post.date ? `${post.date} from ` : 'From: '}}{{ post.author }}</v-card-title>
                <v-card-text class="post-text">{{ post.message }}</v-card-text>
                <v-card-actions v-if="post.attachments.length">
                    <div v-for="attachment of post.attachments" class="mr-5 attachment">
                        <a @click="download(post, attachment)">
                            <v-icon icon="$download" />
                            {{ attachment.name }}
                            {{ (attachment.size / 1024).toFixed(1) }}K
                        </a>
                    </div>
                </v-card-actions>
            </v-card>
        </div>

        <v-dialog v-model="page.hoursDialog" width="500" content-class="staff-hours">
            <vu-form :data="page" ref="form" :save="save">
                <v-card>
                    <v-card-title class="headline">Log Hours</v-card-title>
                    <v-card-text class="mt-5">
                        <v-text-field
                            v-model="page.workDescription"
                            label="Description"></v-text-field>
                        <v-text-field
                            v-model="page.hours"
                            suffix="hours"
                            label="Hours Expended"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="px-4 pb-4">
                        <v-btn variant="elevated" ripple block color="accent" type="submit" @click.prevent="logHours">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </vu-form>
        </v-dialog>

        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.ticket-edit {
    .v-container {
        padding-left: 8px;
        padding-right: 8px;
    }
    .v-textarea {
        .v-field__field {
            background: rgb(var(--v-theme-background));
        }
        .v-field-label {
            color: rgb(var(--v-theme-text-darken-2));
        }
        textarea {
            border: 1px solid rgb(var(--v-theme-border-lighten-1));
            font-size: 1rem;
            line-height: 1.25em !important;
            margin-left: 0;
            padding: 28px 8px 8px 4px;
        }
        textarea::placeholder {
            padding-top: 16px;
        }
        .v-field-label {
            margin-left: 4px;
        }
    }
    .post-text {
        white-space: pre-wrap;
    }
    .close {
        position: absolute;
        padding: 0 !important;
        min-width: 32px !important;
        top: 0;
        right: 0;
    }
    a {
        text-decoration: none;
        color: rgb(var(--v-theme-accent-darken-2));
        cursor: pointer;
    }
}

.staff-hours {
    width: 100%;
    .vu-form {
        padding: 0;
    }
    .headline {
        padding: 12px;
        background-color: rgb(var(--v-theme-primary));
        color: white;
    }
    .v-card {
        width: 100%;
    }
}
</style>
