<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {Feedback, Progress, State, can, fetch} from '@/paks/vu-app'
import {arrayBufferToBase64} from '@/paks/js-base64'
import {Issue, Product} from '@/models'
import Downloads from './Downloads'

const Statuses = ['Closed', 'Fixed', 'Open']
const MaxAttachment = 5 * 1024 * 1024
const MaxAttachments = 10

const Types = ['Bug', 'Discussion', 'Feature', 'Release', 'Security']
const Recommend = ['No Action Required', 'Optional Upgrade', 'Recommended Upgrade', 'Essential Upgrade']

const Priorities = ['None', 'Low', 'Medium', 'High', 'Critical']
const Products = ['Appweb', 'GoAhead', 'Ioto', 'Kickstart', 'Builder', 'Manager']

const props = defineProps({id: String, product: Object})
const emit = defineEmits(['input'])

const page = reactive({
    eval: false,
    issue: {subject: ''},
    plan: null,
    priorities: Priorities,
    products: Products,
    ready: false,
    recommend: Recommend,
    saving: false,
    staff: can('support') && !State.app.mock,
    types: Types,
    uploadFiles: [],
    releases: [],
})
defineExpose({page})

//  Component references
const confirm = ref(null)
const form = ref(null)

watch(() => page.issue.subject, changeSubject)

onMounted(async () => {
    Progress.start('medium')

    if (props.id) {
        page.issue = await Issue.get({id: props.id})
    } else {
        page.issue = initIssue()
    }
    page.issue.notify = false
    let plan = page.plan = State.get('Plan', props.product.planId)
    if (!plan || plan?.scope == 'eval' || !plan.current || plan.current.end < Date.now()) {
        page.eval = 'eval'
    }
    page.ready = true
    Progress.stop()
})

function initIssue() {
    return {
        date: new Date(),
        priority: 'Low',
        status: 'Closed',
        subject: 'Builder',
        tags: ['Builder'],
        type: 'Bug',
        public: false,
        notify: false,
        description: `Minor Patch Release\n\nFeatures\n\n * \n\nFixes\n\n* \n\n`
    }
}

async function save() {
    let {issue} = page
    issue.attachments = await getAttachments()
    let tags = issue.tags || []
    issue.tags = Array.isArray(tags) ? tags : tags.toString().split(',').map((t) => t.trim())
    if (issue.id) {
        await Issue.update(issue)
    } else {
        issue = page.issue = await Issue.create(issue)
    }
    emit('input')
}

async function getAttachments() {
    let attachments = []

    if (page.uploadFiles.length > MaxAttachments) {
        throw new Error('Too many attachments')
    }
    for (let file of page.uploadFiles) {
        if (file.size > MaxAttachment) {
            throw new Error(
                `Attachment ${file.name} is bigger than max ${MaxAttachment / 1024 / 1024} MB`
            )
        }
        let reader = new FileReader()
        reader.readAsArrayBuffer(file)
        let data = await new Promise((resolve, reject) => {
            reader.onload = async () => {
                try {
                    resolve(reader.result)
                } catch (err) {
                    reject(err)
                }
            }
        })
        data = arrayBufferToBase64(data)
        attachments.push({name: file.name, size: file.size, data})
    }
    return attachments
}

async function downloadAttachment(attachment) {
    try {
        let issue = page.issue
        Progress.start('dialog', `Downloading ${attachment.name}`)
        let url = await Issue.download({id: issue.id, key: attachment.key})
        let data = await fetch(url, {
            method: 'GET',
            credentials: false,
            nologout: true,
            progress: true,
            blob: true,
        })
        let blob = new Blob([data])
        let a = document.createElement('a')
        a.download = attachment.name
        a.href = window.URL.createObjectURL(blob)
        document.body.append(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(blob)
    } catch (err) {
        Feedback.error('Cannot download attachment', {err})
    } finally {
        Progress.stop()
    }
}

async function deleteIssue() {
    if (!(await confirm.value.ask('<p>Do you want to delete the issue?</p>'))) {
        return
    }
    Progress.start('dialog', 'Removing Issue')
    await Issue.remove(page.issue)
    Progress.stop()
    Feedback.info('Issue Deleted')
    emit('input')
}

function changeSubject() {
    let issue = page.issue
    if (issue.subject) {
        let set = Downloads[issue.subject].formats.find((f) => f.name == 'Source')
        if (set && set.images) {
            page.releases = set.images.map((i) => i.split('-')[1])
            if (!issue.release) {
                issue.release = page.releases[0]
            }
        }
    }
}

async function downloadRelease() {
    try {
        let issue = page.issue
        let release = page.eval ? 'eval' : issue.release
        let image = `${issue.subject.toLowerCase()}-${release}-src.tgz`
        let url = await Product.download({image, planId: props.product.planId})
        let path = url.split('?')[0].split('/').slice(-1)[0]
        let a = document.createElement('a')
        a.download = path
        Progress.stop()
        Progress.start('dialog', `Downloading ${path}`)

        let data = await fetch(url, {
            method: 'GET',
            credentials: false,
            nologout: true,
            progress: false,
            blob: true,
        })
        let blob = new Blob([data])
        a.href = window.URL.createObjectURL(blob)
        document.body.append(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(blob)
        emit('input')
    } catch (err) {
        Feedback.error(err.message)
    } finally {
        Progress.stop()
    }
}
</script>

<template>
    <vu-form
        class="issue-edit"
        help="/doc/ui/products/issue.html"
        ref="form"
        :data="page"
        :options="{enter: false, progress: 'dialog', title: 'Saving Issue'}"
        :save="save">
        <vu-sign
            name="issue-add"
            :title="`${page.issue.id ? 'Issue Details' : 'Add Issue'}`"
            :subtitle="`Status: ${page.issue.status}`"
            color="accent"
            class="vcol-12"
            :required="true">
                <v-container class="pb-5">
                    <vu-validate ref="validate" _class="mb-5" />
                    <v-row class="mt-0">
                        <v-col cols="6">
                            <vu-input
                                type="select"
                                label="Type"
                                v-model="page.issue.type"
                                :disabled="!page.staff"
                                :items="page.types" />
                        </v-col>
                        <v-col cols="6">
                            <vu-input
                                label="Date"
                                v-model="page.issue.date"
                                type="date"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="6">
                            <vu-input
                                type="select"
                                v-model="page.issue.subject"
                                label="Subject"
                                :items="page.products"
                                :disabled="!page.staff" />
                        </v-col>

                        <v-col cols="6">
                            <vu-input
                                type="combo"
                                label="Release"
                                :items="page.releases"
                                v-model="page.issue.release"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="12">
                            <vu-input
                                label="Title"
                                v-model="page.issue.title"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="12">
                            <vu-input
                                type="textarea"
                                v-model="page.issue.description"
                                label="Description"
                                rows="14"
                                placeholder="Issue details."
                                :readonly="!page.staff" />
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="6">
                            <vu-input
                                type="select"
                                label="Status"
                                v-model="page.issue.status"
                                :items="Statuses"
                                :disabled="!page.staff" />
                        </v-col>
                        <v-col cols="6">
                            <vu-input
                                type="text"
                                label="Impacted Releases"
                                v-model="page.issue.impacted"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <vu-input
                                type="select"
                                v-model="page.issue.priority"
                                label="Issue Priority (CVSS)"
                                :items="page.priorities"
                                :disabled="!page.staff" />
                        </v-col>
                        <v-col cols="6">
                            <vu-input
                                v-model="page.issue.cve"
                                type="text"
                                label="CVE"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12">
                            <vu-input
                                label="Action"
                                type="select"
                                v-model="page.issue.recommend"
                                :items="page.recommend"
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12">
                            <vu-input
                                label="Tags"
                                type="combo"
                                v-model="page.issue.tags"
                                multiple
                                clearable
                                chips
                                :disabled="!page.staff" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 pt-0" v-if="page.staff">
                        <v-col cols="6" class="py-0">
                            <vu-input label="Public" type="checkbox" v-model="page.issue.public" />
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <vu-input label="Notify" type="checkbox" v-model="page.issue.notify" />
                        </v-col>
                    </v-row>
                    <v-row class="pt-0 mt-0">
                        <v-col>
                            <v-btn
                                v-if="page.staff"
                                size="small"
                                class="mr-2"
                                color="accent"
                                type="submit"
                                :disabled="
                                    !page.issue.title ||
                                    !page.issue.description ||
                                    !page.issue.subject
                                "
                                :loading="page.saving">
                                Save
                            </v-btn>
                            <v-btn
                                v-if="!page.staff"
                                size="small"
                                color="accent"
                                class="mr-2"
                                @click="emit('input')">
                                OK
                            </v-btn>
                            <v-btn v-else size="small" color="none" class="mr-2" @click="emit('input')">
                                Cancel
                            </v-btn>
                            <v-btn
                                size="small"
                                color="error"
                                class="mr-2"
                                v-if="page.issue.id && page.staff"
                                @click="deleteIssue">
                                Delete
                            </v-btn>
                            <v-btn
                                size="small"
                                dark
                                color="teal"
                                class="mr-2"
                                v-if="page.issue.id && page.issue.type == 'Release' && page.issue.release"
                                @click="downloadRelease">
                                Download {{ page.eval ? 'Eval' : page.issue.release }} Release
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
        </vu-sign>

        <vu-confirm ref="confirm" />
    </vu-form>
</template>

<style lang="scss">
.issue-edit {
    margin-top: 10px;
    .col {
        padding: 8px 12px 8px 12px !important;
    }
    .v-textarea .v-label {
        padding: 4px;
    }
    textarea {
        margin-top: 8px;
        border: 1px solid rgb(var(--v-theme-border-lighten-1));
        font-size: 1rem;
        padding: 8px;
        line-height: 1.25em !important;
    }
    .issue-text {
        white-space: pre-wrap;
    }
    .close {
        position: absolute;
        padding: 0 !important;
        min-width: 32px !important;
        top: 0;
        right: 0;
    }
    .v-label--is-disabled {
        color: rgba(0, 0, 0, 0.6) !important;
    }
    .v-input--is-disabled input,
    .v-input--is-disabled textarea,
    .v-select__selection--disabled {
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .v-field--disabled {
        opacity: 1 !important;
        color: rgb(var(--v-theme-text)) !important;
    }
}
</style>
