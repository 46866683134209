<script setup>
/*
    VuInput.js - Vue input directive

    <vu-input v-model="value" :name="name" />
    <vu-input v-model="value" :name="name" :type="switch" />
    <vu-input v-model="value" :name="name" :dataType="number" placeholder="42" :rules="set.name" />

    https://vcalendar.io/datepicker.html#form-example

    Attributes:
        v-model="value"
        auto="autocomplete name"
        type="type"
        dataType="dataType"
        label="Text"
        labelClass="Class"
        inputClass="Class"
        itemClass="Class"
        items="Select or radio items"
        placeholder="Placeholder"
        rules="Validation rules"

    Examples:
        items = {
            speed: {
                "1000":  "1000",
                "10000": "10000",
                "40000": "40000",
            },
            mode: {
                "Online": "Online",
                "Offline": "Offline",
            },
        }
 */
import {getCurrentInstance, inject, onMounted, reactive, ref, watch} from 'vue'
import {titlecase} from '@/paks/js-polyfill'
import {State} from '@/paks/vu-state'

const instance = getCurrentInstance()

const dataToDisplay = {
    array: 'text',
    bool: 'switch',
    boolean: 'switch',
    date: 'date',
    number: 'text',
    int: 'text',
    double: 'text',
    object: 'text',
    string: 'text',
    text: 'textarea',
}

const props = defineProps({
    accept: String,
    appendIcon: String,
    autocomplete: {type: String, default: 'none'},
    background: String,
    clearable: {type: Boolean, default: false},
    chips: Boolean,
    cols: [Number, String],
    color: String,
    dataType: String,
    datetime: {type: String, default: 'datetime'},
    density: {type: String, enum: ['default', 'comfortable', 'compact'], default: 'compact'},
    disabled: Boolean,
    error: Boolean,
    errors: Array,
    falseValue: {type: Boolean, default: false},
    hideDetails: {type: [String, Boolean], enum: [true, false, 'auto'], default: false},
    id: String,
    inset: {type: Boolean, default: true},
    inputClass: {type: String, default: ''},
    itemClass: {type: String, default: ''},
    itemTitle: {type: String},
    itemValue: {type: String},
    items: {type: [Array, Object], default: []},
    label: String,
    labelClass: {type: String, default: ''},
    max: [Number, Object],
    menuProps: {type: Object},
    min: [Number, Object],
    modelValue: {type: [String, Array, Boolean, Date, Number, Object], default: null},
    multiple: Boolean,
    name: String,
    numeric: Boolean,
    placeholder: String,
    popover: {
        type: Object,
        default: {
            visibility: 'focus',
            autoHide: true,
            placement: 'bottom-start',
            showDelay: 0,
            hideDelay: 110,
        },
    },
    prefix: String,
    prependIcon: String,
    rows: [Number, String],
    rules: {type: Array, default: () => []},
    suffix: String,
    timezone: {type: String, default: 'local'},
    thumb: {type: Boolean, default: true},
    trueValue: {type: Boolean, default: true},
    type: String,
    variant: {type: String, default: 'underlined'},
})

const emit = defineEmits(['change', 'input', 'update:modelValue'])

const page = reactive({
    attributes: [{dot: 'red', dates: new Date()}],
    classes: null,
    dateConfig: {},
    error: null,
    errors: [],
    label: null,
    name: null,
    value: null,
    timeout: null,
    type: null,
})

const inputs = inject('inputs', [])
const input = ref(null)
const picker = ref(null)
let map = null

watch(() => props.modelValue, renderControls)
watch(() => props.label, renderControls)
watch(() => props.errors, () => {
    renderControls()
})

defineExpose({setError})

onMounted(() => {
    page.name = (props.name || props.label || '').toLowerCase()
    page.classes = `vcol vcol-${props.cols || 12}`
    page.value = props.modelValue
    if (props.type == 'switch' && props.items && props.items.length && !map) {
        map = {}
        for (let [key, value] of Object.entries(props.items)) {
            map['' + value] = key
        }
    }
    if (inputs?.value) {
        inputs.value[page.name] = instance
    }
    renderControls()
})

function renderControls() {
    let dataType = props.dataType || getType(props.modelValue)
    page.type = props.type || dataToDisplay[dataType]
    page.label = props.label != null ? props.label : makeTitle(props.name)

    if (dataType == 'object') {
        page.value = JSON.stringify(props.modelValue)
    } else if (dataType == 'date' || props.modelValue instanceof Date) {
        page.value = new Date(Date.parse(props.modelValue))
    } else {
        if (map) {
            page.value = props.items[props.modelValue]
        } else {
            page.value = props.modelValue
        }
    }
    if (props.errors && props.errors.length) {
        setError(props.errors)
    } else {
        page.error = false
        page.errors = []
    }
}

//  Called for each keystroke of input
function onInput(value) {
    if (page.type == 'date') {
        value = new Date(Date.parse(value))
    }
    page.value = value
    emit('update:modelValue', map ? map[value] : value)
    emit('input', map ? map[value] : value)
    if (page.type == 'slider') {
        clearTimeout(page.timeout)
        page.timeout = setTimeout(() => {
            //  Vuetify not emitting onChange event for slider
            onChange()
        }, 500)
    }
}

//  Called when the input is defocussed 
function onChange() {
    let value = page.value
    if (page.type == 'date') {
        value = new Date(Date.parse(value))
    }
    page.value = value
    emit('change', map ? map[value] : value)
}

function getType(v) {
    if (typeof v == 'boolean') {
        return 'boolean'
    } else if (typeof v == 'string') {
        return 'string'
    } else if (typeof v == 'number') {
        return 'number'
    } else if (v instanceof Date) {
        return 'date'
    } else if (Array.isArray(v)) {
        return 'array'
    } else if (typeof v == 'object' && v != null) {
        return 'object'
    }
    return 'string'
}

function makeTitle(str = '') {
    str = str.replace(/[A-Z][a-zA-Z0-9$_]*/g, ' $&')
    let words = str.split(/[ \.]/g)
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }
    return words.join(' ')
}

function setError(messages) {
    if (messages) {
        if (!Array.isArray(messages)) {
            messages = [messages]
        }
        for (let message of messages) {
            page.errors.push(message)
            page.error = true
        }
    } else {
        page.error = false
    }
}

//  Workaround for v-calendar not setting inputValue until the user updates
function getDate() {
    let when = page.value
    let dateFormatter = new Intl.DateTimeFormat(navigator?.lanaguage || 'en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
    let timeFormatter = new Intl.DateTimeFormat(navigator?.lanaguage || 'en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    })
    if (props.datetime == 'time') {
        return timeFormatter.format(when)
    } else if (props.datetime == 'date') {
        return dateFormatter.format(when)
    } else {
        return `${dateFormatter.format(when)} ${timeFormatter.format(when)}`
    }
}

function updateDate(updateValue, event) {
    let when = event.target.value
    if (!isNaN(new Date(Date.parse(when)))) {
        updateValue(when)
    } else {
        //  Just ignore until user corrects
    }
}

function wrapEvents(events) {
    let wrapped = Object.assign({}, events)
    delete wrapped.change
    delete wrapped.input
    return wrapped
}
</script>

<template>
    <div class="vu-input" :class="page.classes">
        <v-text-field
            v-if="page.type == 'label'"
            :autocomplete="props.autocomplete"
            :bg-color="background"
            :class="inputClass"
            :density="density"
            :disabled="true"
            :color="color"
            :label="page.label"
            :modelValue="page.value"
            :name="page.name"
            :placeholder="placeholder"
            :prefix="prefix"
            :suffix="suffix"
            :type="type"
            :variant="props.variant"
            ref="input"
            @change="onChange"
            @update:modelValue="onInput" />

        <v-text-field
            v-if="page.type == 'text' || page.type == 'password'"
            :append-icon="appendIcon"
            :autocomplete="props.autocomplete"
            :bg-color="background"
            :type="type"
            :class="inputClass"
            :clearable="clearable"
            :color="color"
            :density="density"
            :disabled="disabled"
            :error="page.error"
            :error-messages="page.errors"
            :hide-details="hideDetails"
            :id="id ? id : `id-${page.name}`"
            :label="page.label"
            :modelValue="page.value"
            :name="page.name"
            :placeholder="placeholder"
            :prefix="prefix"
            :prepend-icon="prependIcon"
            :rules="rules"
            :suffix="suffix"
            :variant="props.variant"
            ref="input"
            @change="onChange"
            @update:modelValue="onInput" />

        <v-textarea
            v-if="page.type == 'textarea'"
            :color="color"
            :bg-color="background"
            :autocomplete="props.autocomplete"
            :clearable="clearable"
            :density="density"
            :disabled="disabled"
            :error="page.error"
            :error-messages="page.errors"
            :hide-details="hideDetails"
            :label="page.label"
            :modelValue="page.value"
            :name="page.name"
            :placeholder="placeholder"
            :rows="rows"
            :rules="rules"
            :variant="props.variant"
            ref="input"
            @change="onChange"
            @update:modelValue="onInput" />

        <label v-if="page.type == 'checkbox'" class="v-checkbox-label">{{ page.label }}</label>
        <v-checkbox
            v-if="page.type == 'checkbox'"
            :autocomplete="props.autocomplete"
            :bg-color="background"
            :class="inputClass"
            :color="color || 'primary'"
            :density="density"
            :disabled="disabled"
            :error="page.error"
            :error-messages="page.errors"
            :hide-details="hideDetails"
            :modelValue="page.value"
            :name="page.name"
            :variant="props.variant"
            inline
            @change="onChange"
            @update:modelValue="onInput"></v-checkbox>

        <v-radio-group
            v-if="page.type == 'radio'"
            inline
            :autocomplete="props.autocomplete"
            :bg-color="background"
            :class="inputClass"
            :color="color || 'primary'"
            :density="density"
            :disabled="disabled"
            :error="page.error"
            :error-messages="page.errors"
            :hide-details="hideDetails"
            :label="page.label"
            :modelValue="page.value"
            :name="page.name"
            :variant="props.variant"
            @change="onChange"
            @update:modelValue="onInput">
            <v-radio
                v-if="Array.isArray(items)"
                v-for="label in items"
                :class="itemClass"
                :key="`radio-${label}`"
                :label="label"
                :value="label" />
            <v-radio
                v-else
                v-for="(value, name) in items"
                :class="itemClass"
                :key="`radio-${name}`"
                :label="name"
                :value="value" />
        </v-radio-group>

        <v-select
            v-if="page.type == 'select'"
            :autocomplete="props.autocomplete"
            :bg-color="background"
            :class="inputClass"
            :color="color"
            :density="density"
            :disabled="disabled"
            :error="page.error"
            :error-messages="page.errors"
            :hide-details="hideDetails"
            :items="items"
            :item-value="itemValue"
            :item-title="itemTitle"
            :label="page.label"
            :menu-props="menuProps"
            :modelValue="page.value"
            :multiple="multiple"
            :name="page.name"
            :variant="props.variant"
            :rules="rules"
            nofilter
            @change="onChange"
            @update:modelValue="onInput" />

        <!-- Switch style checkbox -->
        <div v-if="page.type == 'switch'">
            <v-label v-if="page.label">{{ titlecase(page.label) }}</v-label>
            <v-switch
                v-if="page.type == 'switch'"
                :autocomplete="props.autocomplete"
                :inset="inset"
                :color="color || 'accent'"
                :class="inputClass"
                :density="density"
                :disabled="disabled"
                :error="page.error"
                :error-messages="page.errors"
                :false-value="props.falseValue"
                :hide-details="hideDetails"
                :label="props.suffix"
                :menu-props="menuProps"
                :modelValue="page.value"
                :name="page.name"
                :true-value="props.trueValue"
                :variant="props.variant"
                @change="onChange"
                @update:modelValue="onInput" />
        </div>

        <div v-if="page.type == 'combo'">
            <v-combobox
                :autocomplete="autocomplete"
                :bg-color="background"
                :class="inputClass"
                :clearable="clearable"
                :chips="chips"
                :color="color"
                :density="density"
                :disabled="disabled"
                :error="page.error"
                :error-messages="page.errors"
                :hide-details="hideDetails"
                :items="items"
                :item-value="itemValue"
                :item-title="itemTitle"
                :label="page.label"
                :menu-props="menuProps"
                :modelValue="page.value"
                :multiple="multiple"
                :name="page.name"
                :rules="rules"
                :variant="props.variant"
                @change="onChange"
                @update:modelValue="onInput" />
        </div>

        <div v-if="page.type == 'slider'">
            <v-slider
                :append-icon="appendIcon"
                :autocomplete="props.autocomplete"
                :bg-color="background"
                :class="inputClass"
                :color="color"
                :density="density"
                :disabled="disabled"
                :error="page.error"
                :error-messages="page.errors"
                :hide-details="hideDetails"
                :label="page.label"
                :max="max"
                :min="min"
                :modelValue="page.value"
                :name="page.name"
                :prepend-icon="prependIcon"
                :step="1"
                :thumb-label="thumb"
                :variant="props.variant"
                @change="onChange"
                @update:modelValue="onInput">
                <template v-slot:append>
                    <v-text-field
                        v-if="numeric"
                        v-model="page.value"
                        density="compact"
                        class="slider-number"
                        type="number"
                        variant="plain"
                        hide-details />
                </template>
            </v-slider>
        </div>

        <!-- vcalendar picker -->
        <div v-if="page.type == 'date'" class="date-picker">
            <vu-date-picker
                ref="picker"
                v-model="page.value"
                :attributes="page.attributes"
                :autocomplete="props.autocomplete"
                :is-dark="State.app.dark"
                :density="density"
                :hide-details="hideDetails"
                :max-date="max"
                :min-date="min"
                :mode="datetime"
                :name="page.name"
                :popover="popover"
                :timezone="timezone == 'local' ? null : 'UTC'"
                :update-on-input="true"
                :variant="props.variant"
                @change="onChange"
                @update:modelValue="onInput">
                <template #default="{inputEvents, inputValue, updateValue}">
                    <v-text-field
                        prepend-icon="$calendar"
                        v-on="wrapEvents(inputEvents)"
                        class="date-input"
                        :model-value="getDate(inputValue)"
                        :density="density"
                        :disabled="disabled"
                        :error="page.error"
                        :error-messages="page.errors"
                        :label="page.label"
                        :name="page.name"
                        :placeholder="placeholder"
                        :prefix="prefix"
                        :rules="rules"
                        :suffix="suffix"
                        @change="updateDate(updateValue, $event)" />
                </template>
            </vu-date-picker>
        </div>
        <div v-if="page.type == 'file'">
            <v-label v-if="page.label">{{ titlecase(page.label) }}</v-label>
            <v-file-input
                class="mb-4"
                show-size
                :accept="accept"
                :clearable="clearable"
                :density="density"
                :error="page.error"
                :error-messages="page.errors"
                :hide-details="hideDetails"
                :label="placeholder"
                :modelValue="page.value"
                :multiple="multiple"
                :name="page.name"
                :variant="props.variant"
                ref="input"
                @change="onChange"
                @update:modelValue="onInput" />
        </div>
    </div>
</template>

<style lang="scss">
.vu-input {
    flex: 1 1 auto;
    max-width: 100%;
    min-width: 0px;
    --v-disabled-opacity: 1;
    .v-checkbox-label {
        opacity: var(--v-medium-emphasis-opacity);
        color: rgba(var(--v-theme-text));
    }
    .v-textarea {
        .v-field-label {
            color: rgb(var(--v-theme-text-darken-2));
            padding-left: 4px;
        }
        .v-field--active .v-field-label {
            padding: 0;
        }
        textarea {
            font-size: 1rem;
            line-height: 1.25em !important;
            margin-left: 0;
            padding: 18px 8px 8px 8px;
        }
        textarea::placeholder {
            padding-top: 16px;
        }
        .v-field-label {
            // margin-left: 12px;
        }
    }
    .v-checkbox {
        .v-selection-control__wrapper,
        .v-selection-control__input {
            justify-content: left !important;
        }
    }
    .v-radio-group {
        .v-input__control {
            .v-selection-control-group {
                padding-inline-start: 0 !important;
                padding-left: 0 !important;
                margin-left: -4px !important;
            }
            .v-selection-control {
                margin-right: 4px;
            }
            .v-label {
                margin-inline-start: 0 !important;
            }
        }
    }
    .v-combobox--chips {
        .v-field {
            padding-bottom: 4px;
        }
    }
    .v-field__clearable {
        padding-bottom: 4px;
        font-size: 12px;
    }
    .v-slider.v-input--horizontal {
        margin-inline: 0 0 !important;
    }
    .slider-number {
        width: 60px;
        input {
            text-align: right;
            padding-inline: 0 0 !important;
        }
    }
    ::placeholder {
        font-size: 1rem;
    }
}
.v-select__content {
    .v-list {
        background: rgb(var(--v-theme-background-lighten-1)) !important;
    }
}
</style>

<style lang="scss" scoped>
.pick-items {
    font-size: 1rem;
}
.v-list-item {
    min-height: 30px !important;
}
.v-list-item__content {
    padding: 6px 0 6px 0 !important;
}
.v-list {
    padding: 0;
}
</style>
